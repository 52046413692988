<template lang="pug">
v-row
  v-col(cols="12")
    excel-table(
      v-if="this.typeNames ? this.typeNames.length : false"
      :typeNames="typeNames"
    )
    div(
      v-else
    ) Произошла ошибка при загрузке данных
</template>

<script>
import ExcelTable from './ExcelTable';

export default {
  components: { ExcelTable },
  data() {
    return {
      errors: null,
    };
  },

  computed: {
    typeNames: {
      get() {
        return this.$store.getters['NOTIFICATIONS/typeNames'];
      },
    },
  },

  mounted() {
    if (!this.typeNames.length) {
      this.$store.dispatch('NOTIFICATIONS/getTypes');
    }
  },
};
</script>
