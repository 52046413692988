import { validator } from '@/utils/validation';
import { email, isTrue } from '@/utils/validators';
import {
  or,
  required,
  maxLength,
  integer,
  helpers,
} from 'vuelidate/lib/validators';

const phoneForTabel = helpers.regex(
  'phone',
  /^\+?7\s?\(?[0-9]{3}\)?\s?[0-9]{3}-?[0-9]{2}-?[0-9]{2}$/,
);

export default validator({
  preContact: {
    contact: or(phoneForTabel, email),
  },
  contact: { required, contact: or(phoneForTabel, email) },
  orgId: { integer, maxSymbolsLength: maxLength(125) }, // FIXME: WTF?
  hostName: { maxSymbolsLength: maxLength(125) },
  validHostOrg: { required: isTrue },
  types: { required },
});
