<template lang="pug">
v-container(p-5 fluid)
  v-row
    v-col(cols="12")
      v-card
        v-card-title Как работать с таблицей:
        v-card-text
          ol.pb-3
            li Для редактирования ячейки кликните по ней два раза.
            li Можно копировать нажатием клавиш ctrl+c и ctrl+v из других таблиц.
            li Ширину столбцов можно менять, двигая мышкой край столбца в шапке таблицы.
            li Можно перейти на вкладку Список получателей, а данные, введенные в таблицу, сохранятся.
            li Поле ID организации или поле Хостнейм должно быть заполнено.
            li Типы нотификаций - обязательное поле.
            li Телефон должен начинаться с 7 или +7 и иметь 11 цифр.
            li Открыть список типов нотификаций можно двойным кликом, а можно клавишей Backspace.
    v-col(cols="12")
      v-card
        v-btn(
          text
          color="primary"
          @click="jExcelObj.insertRow()"
        ) Добавить строку
        div(class="wrapper-jexcel" style="overflow: auto")
          div(id="spreadsheet" ref="spreadsheet")
        div.pl-5(v-if="$v.$dirty && $v.orgId.$invalid" class="red--text")
          | невалидное значение ID организации
        div.pl-5(v-if="$v.$dirty && $v.contact.$invalid" class="red--text")
          | невалидное значение контакта
        div.pl-5(v-for="errMesg in validateErr" class="red--text") {{ errMesg }}
        span
          v-btn(
            text
            color="primary"
            @click="addRecipient"
            :disabled="errorCells.length !== 0"
          ) Добавить получателей
        span
          v-btn(
            text
            color="primary"
            @click="deleteRecipient"
            :disabled="errorCells.length !== 0"
          ) Удалить получателей
        span
          v-btn(
            text
            color="primary"
            @click="clearTable"
          ) Очистить таблицу
        span
          v-btn(
            text
            color="primary"
            v-if="$can('READ_NOTIFY_RECIPIENT')"
            to="list"
          ) Вернуться к списку получателей
</template>

<script>
import mixins from '@/utils/mixins';
import 'jexcel/dist/jexcel.css';
import jexcel from 'jexcel';
import Validate from '@/validations/notificationsImport';
import { mapActions, mapGetters } from 'vuex';

const lightRed = '#f76d65';

export default mixins(Validate).extend({
  data() {
    return {
      errors: null,
      orgId: null,
      hostName: null,
      types: null,
      validateErr: [],
      errorCells: [],
      contact: null,
      preContact: null,
      recipient: [['', '', '', '']],
      // recipient: [
      //   ['111', '', '8', 'v.grechin@medpoint24.ru'],
      //   ['22', '', '5', 'test1@test.ru'],
      //   ['', 'ТестХост', '4', 'test2@test.ru'],
      // ],
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS', ['typeNames', 'orgIds', 'hostNames']),

    validHostOrg() {
      // для проверки что есть или хост или орг id
      return (
        (Boolean(this.orgId) || Boolean(this.hostName)) &&
        Boolean(this.orgId) !== Boolean(this.hostName)
      );
    },

    jExcelOptions() {
      return {
        data: this.recipient,
        columns: [
          { type: 'text', title: 'ID организации', width: '180px' },
          { type: 'text', title: 'Хостнейм', width: '270px', wordWrap: true },
          {
            type: 'dropdown',
            title: 'Типы нотификаций',
            width: '450px',
            source: this.typeNames,
            multiple: true,
          },
          { type: 'text', title: 'Контакт', width: '250px' },
        ],
        onchange: this.changedCell,
        oneditionstart: this.startEditCell,
        allowInsertColumn: false,
        allowManualInsertColumn: false,
        allowDeleteColumn: false,
        allowRenameColumn: false,
      };
    },
  },

  mounted() {
    const jExcelObj = jexcel(this.$refs['spreadsheet'], this.jExcelOptions);
    Object.assign(this, { jExcelObj }); // tucks all methods under jExcelObj object in component instance
    const savedTableData = this.$store.getters['NOTIFICATIONS/savedTableData'];
    if (savedTableData.length) {
      jExcelObj.setData(savedTableData);
    } else {
      jExcelObj.insertRow(9);
    }

    if (!this.orgIds.length) {
      this.$store.dispatch('NOTIFICATIONS/queryOrgList');
    }

    if (!this.hostNames.length) {
      this.$store.dispatch('NOTIFICATIONS/queryHostList');
    }
  },

  methods: {
    ...mapActions('NOTIFICATIONS', {
      import: 'import',
    }),

    startEditCell(instance, cell, x, y) {
      var cellName = jexcel.getColumnNameFromId([x, y]);
      this.jExcelObj.setStyle(cellName, 'background-color', '#fff');
    },

    // проверяем что ввели в ячейку
    changedCell(instance, cell, x, y, value) {
      var cellName = jexcel.getColumnNameFromId([x, y]);
      switch (x) {
        case '0':
        case 0:
          // проверяем что ID число
          this.orgId = value;
          this.$v.orgId.$touch();
          if (this.$v.orgId.$invalid) {
            // подсвечиваем красным
            this.jExcelObj.setStyle(cellName, 'background-color', lightRed);
            if (!this.errorCells.includes(cellName))
              this.errorCells.push(cellName);
            this.$notify({
              group: 'error',
              type: 'info',
              title: 'Неверный формат ID организации',
              text: '',
            });
            // проверяем что эта организация подключена к нотификациям
          } else if (this.orgId && !this.orgIds.includes(Number(this.orgId))) {
            // подсвечиваем красным
            this.jExcelObj.setStyle(cellName, 'background-color', lightRed);
            if (!this.errorCells.includes(cellName))
              this.errorCells.push(cellName);
            this.$notify({
              group: 'error',
              type: 'info',
              title: 'Организации не подключена к нотификациям',
              text: '',
            });
          } else if (this.errorCells.includes(cellName)) {
            this.jExcelObj.setStyle(cellName, 'background-color', '#fff');
            this.errorCells.splice(this.errorCells.indexOf(cellName), 1);
          }
          break;
        case '1':
        case 1:
          // проверяем Хостнейм
          this.hostName = value;
          this.$v.hostName.$touch();
          if (this.$v.hostName.$invalid) {
            // подсвечиваем красным
            this.jExcelObj.setStyle(cellName, 'background-color', lightRed);
            if (!this.errorCells.includes(cellName))
              this.errorCells.push(cellName);
            this.$notify({
              group: 'error',
              type: 'info',
              title: 'Не более 125 символов',
              text: '',
            });
            // проверяем что этот хостнейм подключен к нотификациям
          } else if (this.hostName && !this.hostNames.includes(this.hostName)) {
            // подсвечиваем красным
            this.jExcelObj.setStyle(cellName, 'background-color', lightRed);
            if (!this.errorCells.includes(cellName))
              this.errorCells.push(cellName);
            this.$notify({
              group: 'error',
              type: 'info',
              title: 'Хостнейм не подключен к нотификациям',
              text: '',
            });
          } else if (this.errorCells.includes(cellName)) {
            this.jExcelObj.setStyle(cellName, 'background-color', '#fff');
            this.errorCells.splice(this.errorCells.indexOf(cellName), 1);
          }
          break;
        case '3':
        case 3:
          this.preContact = value;
          this.$v.preContact.$touch();
          if (this.$v.preContact.$invalid) {
            // подсвечиваем красным
            const cellStyle = this.jExcelObj.getStyle(cellName);
            if (!cellStyle.includes(`background-color: ${lightRed}`)) {
              this.jExcelObj.setStyle(cellName, 'background-color', lightRed);
            }
            if (!this.errorCells.includes(cellName))
              this.errorCells.push(cellName);
            this.$notify({
              group: 'error',
              type: 'info',
              title: 'Неверный формат контакта',
              text: '',
            });
          } else {
            this.jExcelObj.setStyle(cellName, 'background-color', '#fff');
            this.errorCells.splice(this.errorCells.indexOf(cellName), 1);
          }
          break;
        default:
      }

      this.$store.commit(
        'NOTIFICATIONS/savedTableData',
        this.jExcelObj.getData(),
      );
    },

    errorMessage(validate) {
      const errorMessage = [];
      if (this.$v.contact.$invalid) {
        errorMessage.push('Неверный формат контакта');
      }
      if (this.$v.orgId.$invalid) {
        errorMessage.push('Неверный формат ID организации');
      }
      if (this.$v.hostName.$invalid) {
        errorMessage.push('Неверный формат хостнейма');
      }
      if (this.$v.validHostOrg.$invalid) {
        errorMessage.push('Укажите ID организации или Хостнейм');
      }
      if (this.$v.types.$invalid) {
        errorMessage.push('Типы нотификаций обязательное поле');
      }

      return errorMessage;
    },

    clearTable() {
      this.jExcelObj.setData([['', '', '', '']]);
      this.jExcelObj.insertRow(9);
      this.$store.commit('NOTIFICATIONS/savedTableData', []);
    },

    validateAllData() {
      const tableData = this.jExcelObj.getData();
      const requestArr = [];
      this.validateErr = [];

      tableData.forEach((row, index) => {
        // проверка что строка не пустая
        if (row[0] || row[1] || row[2] || row[3]) {
          this.orgId = row[0];
          this.hostName = row[1];
          this.types = row[2];
          if (row[3].includes('@')) {
            this.contact = row[3];
          } else {
            this.contact = row[3].replace(/[+()\s-]/g, '');
          }
          this.$v.$touch();
          if (this.$v.$invalid) {
            // !! показать ошибки
            const errMes = this.errorMessage(this.$v);
            this.validateErr.push(
              `В строке ${index + 1} невалидные данные: ${errMes.join(', ')}`,
            );
            return;
          }
          const requestObj = {
            firstname: 'Тест',
            surname: 'Тест',
            patronymic: 'Тест',
            contact: this.contact,
            typeIds: this.types.split(';').map(Number),
          };

          if (this.hostName) {
            requestObj.hostName = this.hostName;
          } else if (this.orgId) {
            requestObj.orgId = Number(this.orgId);
          }

          requestArr.push(requestObj);
        }
      });

      return !this.validateErr.length ? requestArr : null;
    },

    addRecipient() {
      const requestArr = this.validateAllData();
      if (requestArr && requestArr.length) {
        this.sendAddRequest(requestArr);
      } else {
        this.$notify({
          group: 'error',
          type: 'info',
          title: 'Нет данных для добавления',
          text: '',
        });
      }
    },

    deleteRecipient() {
      const requestArr = this.validateAllData();
      if (requestArr.length) {
        this.sendDeleteRequest(requestArr);
      } else {
        this.$notify({
          group: 'error',
          type: 'info',
          title: 'Нет данных для удаления',
          text: '',
        });
      }
    },

    async sendAddRequest(requestData) {
      try {
        const data = await this.$store.dispatch(
          'NOTIFICATIONS/edit',
          requestData,
        );
        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Добавлено: ' + data.length,
          text: '',
        });
        // this.clearTable();
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'info',
          title: 'Произошла ошибка добавления получателей.',
          text: '',
        });
      }
    },

    async sendDeleteRequest(requestData) {
      try {
        const data = await this.$store.dispatch(
          'NOTIFICATIONS/deleteMass',
          requestData,
        );
        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Удалено: ' + data.length,
          text: '',
        });
        // this.clearTable();
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'info',
          title: 'Произошла ошибка удаления получателей.',
          text: '',
        });
      }
    },
  },
});
</script>
